<template>
  <b-card>
    <div class="desc-wrap">
      <div class="desc-label">
        风险类别：
      </div>
      <div class="desc-text">
        {{ riskInfo.riskType }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        风险等级：
      </div>
      <div class="desc-text">
        <b-badge
          class="d-flex justify-content-center"
          style="align-items: center"
          pill
          :variant="levelColorObj[riskInfo.riskLevel]"
        >
          {{ levelObj[riskInfo.riskLevel] }}
        </b-badge>
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        关联对象：
      </div>
      <div class="desc-text">
        {{ riskInfo.riskObjectName }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        风险来源：
      </div>
      <div class="desc-text">
        {{ riskInfo.riskSourceName }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        风险产生时间：
      </div>
      <div class="desc-text">
        {{ riskInfo.createTime }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        风险入库时间：
      </div>
      <div class="desc-text">
        {{ riskInfo.assignTime }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        风险描述：
      </div>
      <div class="desc-text">
        {{ riskInfo.explanation }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        处置建议：
      </div>
      <div class="desc-text">
        {{ riskInfo.disposalAdvice }}
      </div>
    </div>
    <div
      v-if="currentPath == '/risk-histroydetail'"
      class="desc-wrap"
    >
      <div class="desc-label">
        处理人：
      </div>
      <div class="desc-text">
        <el-tag
          v-for="(item,index) in riskInfo.disposalUsers"
          :key="index"
          class="mr10"
        >
          {{ item.actualName }}
        </el-tag>
      </div>
    </div>
    <div
      v-if="currentPath == '/risk-histroydetail'"
      class="desc-wrap"
    >
      <div class="desc-label">
        处理结果：
      </div>
      <div class="desc-text">
        {{ riskInfo.disposalResult }}
      </div>
    </div>
    <div class="desc-wrap">
      <div class="desc-label">
        风险处理情况：
      </div>
      <app-timeline>
        <app-timeline-item
          v-for="(detailItem,index) in riskInfo.historyList"
          :key="index"
          :variant="variantObj[detailItem.riskStatus]"
        >
          {{ detailItem.createTime }} {{ detailItem.explanation.replace('[','"').replace(']','"') }}
        </app-timeline-item>
      </app-timeline>
    </div>
    <div class="text-center mt20">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        v-if="btnShow(1)"
        type="primary"
        @click="edit"
      >
        修改
      </el-button>
      <el-button
        v-if="btnShow(2)"
        type="primary"
        @click="assignDialogShow"
      >
        指派
      </el-button>
      <el-button
        v-if="btnShow(3)"
        type="primary"
        @click="delRisk"
      >
        删除
      </el-button>
      <el-button
        v-if="btnShow(4)"
        type="primary"
        @click="closeRisk"
      >
        关闭
      </el-button>
      <el-button
        v-if="btnShow(5)"
        type="primary"
        @click="riskDialogShow('riskConfrum')"
      >
        审核
      </el-button>
      <el-button
        v-if="btnShow(6)"
        type="primary"
        @click="riskDialogShow('riskReject')"
      >
        驳回
      </el-button>
      <el-button
        v-if="btnShow(7)"
        type="primary"
        @click="riskDialogShow('riskRun')"
      >
        处理
      </el-button>
    </div>
    <RiskAssign
      ref="riskAssign"
      @handleFilter="back"
    />
    <RiskConfrum
      ref="riskConfrum"
      @handleFilter="back"
    />
    <RiskReject
      ref="riskReject"
      @handleFilter="back"
    />
    <RiskRun
      ref="riskRun"
      @handleFilter="back"
    />
  </b-card>
</template>
<script>
import { GetRiskManageDetail, DeleteRiskManageByIds, CloseRisk } from '@/api/risk/risk'
import { getUserData } from '@/auth/utils'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import RiskAssign from '@/views/risk/riskPerception/RiskAssign'
import RiskConfrum from '@/views/risk/riskRectify/RiskConfrim.vue'
import RiskReject from '@/views/risk/riskRectify/RiskReject.vue'
import RiskRun from '@/views/risk/riskRectify/RiskRun.vue'
import { success, error } from '@core/utils/utils'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    RiskReject,
    RiskRun,
    RiskConfrum,
    RiskAssign,
  },
  data() {
    return {
      currentPath: this.$route.path,
      variantObj: {
        1: 'secondary',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'warning',
        6: 'success',
        7: 'dark',
        8: 'dark',
      },
      colorObj: {
        1: 'secondary',
        2: 'danger',
        3: 'warning',
        4: 'info',
        5: 'warning',
        6: 'success',
        7: 'dark',
        8: 'dark',
      },
      riskStatusObj: {
        1: '待处理',
        2: '驳回',
        3: '待整改',
        4: '待审核',
        5: '未通过审核',
        6: '处理完成',
        7: '关闭',
        8: '自动关闭',
      },
      riskInfo: {
        riskType: '',
        riskLevel: '',
        riskSourceName: '',
        createTime: '',
        assignTime: '',
        riskObjectId: '',
        explanation: '',
        riskObjectName: '',
        disposalAdvice: '',
        riskObjectType: '',
        historyList: [],
      },
      levelObj: {
        1: '极低',
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
      levelColorObj: {
        5: 'danger',
        4: 'warning',
        3: 'light-warning',
        2: 'success',
        1: 'success',
      },
      riskId: Number(this.$route.query.id),
    }
  },
  created() {
    this.getRiskManageDetail()
  },
  mounted() {
    if (this.currentPath === '/risk-perceptionDetail') {
      this.navActiveInit(1, '风险感知', '风险管理')
    }
    //
    if (this.currentPath === '/risk-rectifydetail') {
      this.navActiveInit(1, '风险整改', '风险管理')
    }
    if (this.currentPath === '/risk-histroydetail') {
      this.navActiveInit(1, '风险历史', '风险管理')
    }
  },
  destroyed() {
    if (this.currentPath === '/risk-perceptionDetail') {
      this.navActiveInit(2, '风险感知', '风险管理', '/risk-perception')
    }
    if (this.currentPath === '/risk-rectifydetail') {
      this.navActiveInit(2, '风险整改', '风险管理', '/risk-rectify')
    }
    if (this.currentPath === '/risk-histroydetail') {
      this.navActiveInit(2, '风险历史', '风险管理', '/risk-history')
    }
  },
  methods: {
    btnShow(type) {
      let flag = false
      const { actualName } = getUserData()
      switch (type) {
        case 1:
          flag = this.currentPath === '/risk-perceptionDetail'
          break
        case 2:
          flag = this.currentPath === '/risk-perceptionDetail'
            || ((this.riskInfo.riskStatus == 3 || this.riskInfo.riskStatus == 5) && this.riskInfo.assignUserName.indexOf(actualName) != -1)
          break
        case 3:
          flag = this.currentPath !== '/risk-rectifydetail'
          break
        case 4:
          flag = this.currentPath === '/risk-perceptionDetail'
          break
        case 5:
          flag = this.riskInfo.riskStatus == 4 && this.riskInfo.disposalUsers.findIndex(user => user.actualName === actualName) != -1
          break
        case 6:
        case 7:
          flag = (this.riskInfo.riskStatus == 3 || this.riskInfo.riskStatus == 5) && this.riskInfo.disposalUsers.findIndex(user => user.actualName === actualName) != -1
          break
        default:
          break
      }
      return flag
    },
    riskDialogShow(ref) {
      this.$refs[ref].dialogVisible = true
      this.$refs[ref].form.riskManageId = this.riskInfo.id
    },
    closeRisk() {
      this.$confirm('是否确认关闭该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        CloseRisk({ id: this.riskInfo.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.back()
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
    delRisk() {
      this.$confirm('是否确认删除该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteRiskManageByIds({ ids: [this.riskInfo.id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.back()
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
    assignDialogShow() {
      this.$refs.riskAssign.dialogVisible = true
      this.$refs.riskAssign.form.riskManageIds = [this.riskInfo.id]
      this.riskInfo.forecastFinishTime && (this.$refs.riskAssign.form.finishDate = this.riskInfo.forecastFinishTime)
      this.riskInfo.disposalUsers.length > 0 && (this.$refs.riskAssign.form.disposalUserIds = [...new Set(this.riskInfo.disposalUsers.map(u => u.id))])
    },
    edit() {
      this.$router.push({
        path: '/risk-save',
        query: {
          id: this.riskInfo.id,
        },
      })
    },
    back() {
      const path = {
        '/risk-perceptionDetail': '/risk-perception',
        '/risk-rectifydetail': '/risk-rectify',
        '/risk-histroydetail': '/risk-history',
      }
      this.$router.push({
        path: path[this.currentPath],
      })
    },
    getRiskManageDetail() {
      GetRiskManageDetail({ id: Number(this.riskId) }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.riskInfo = resData.data
        }
      })
    },
  },
}
</script>
<style>
</style>
